$marker-color: #000000;
$marker-color-primary: #0009bd;
$marker-color-danger: #aa0000;

.cm-chessboard {
  .markers {
    pointer-events: none;

    .marker {
      &.marker-frame {
        stroke: $marker-color;
        stroke-width: 1.8px;
        opacity: 0.5;
      }

      &.marker-frame-primary {
        stroke: $marker-color-primary;
        stroke-width: 1.8px;
        opacity: 0.4;
      }

      &.marker-frame-danger {
        stroke: $marker-color-danger;
        stroke-width: 1.8px;
        opacity: 0.4;
      }

      &.marker-circle {
        stroke: $marker-color;
        stroke-width: 3px;
        opacity: 0.3;
      }

      &.marker-circle-primary {
        stroke: $marker-color-primary;
        stroke-width: 3px;
        opacity: 0.4;
      }

      &.marker-circle-danger {
        stroke: $marker-color-danger;
        stroke-width: 3px;
        opacity: 0.4;
      }

      &.marker-square {
        fill: black;
        opacity: 0.11;
      }

      &.marker-dot {
        fill: black;
        opacity: 0.2;
      }

      &.marker-bevel {
        fill: black;
        opacity: 0.2;
      }

    }
  }
}
